<template>
  <div>
    <Loading :show="showLoading"/>
    <div class="list_bg">
      <div class="header">
        <div style="width:70%;">
          <img src="../../../assets/image/icon3@2x.png" style="width:19px;height:19px;">
          <span style="font-size: 18px;font-weight: 500;margin-left:8px;margin-top:-3px;">项目列表</span>
        </div>
        <div style="width:30%;text-align: right;" @click="showCategory=!showCategory">
          <span style="font-size: 14px;color:#333;margin-right:4px">分项选择</span>
          <van-icon name="arrow" color="#2F4891"/>
        </div>
      </div>
    </div>
    <div class="list_body">
        <!-- <van-field class="category-li" v-model="parentCategoryStr" is-link readonly @click="showCategory = true"/> -->
        <div class="category-li" @click="showCategory = true">{{parentCategoryStr}}</div>
        <van-popup v-model:show="showCategory" closeable round position="bottom">
        <vs-tree :data="cascaderOptions" @click="onFinish" :showIcon="true" :expandLevel="-1"></vs-tree>
      </van-popup>
      <table style="width:100%;">
        <thead>
          <tr>
            <!-- <th style="width:20%">物料名称</th> -->
            <th style="width:20%">品牌</th>
            <th style="width:20%">订货号</th>
            <!-- <th style="width:30%">分类</th> -->
            <th style="width:15%;text-align: center;">单价</th>
            <th style="width:10%;text-align: center;">数量</th>
            <th style="width:15%;text-align: center;">小计</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="i" v-for="(item, i) in itemList">
            <!-- <td style="width:30%">{{item.material_name}}</td> -->
            <td style="width:20%">{{item.brand_name}}</td>
            <td style="width:20%">{{item.material_order}}</td>
            <td style="width:15%;text-align: center;">{{item.money}}</td>
            <td style="width:10%;text-align: center;">{{item.number}}</td>
            <td style="width:15%;text-align: center;">{{item.price}}</td>
          </tr>
        </tbody>
      </table>
      <van-pagination
        v-model="page"
        :total-items="itemCount"
        :items-per-page="10"
        mode="simple"
        @change="gotoChange"
      />
      <div class="footer-li">
        <span style="color: #666666;font-size: 12px;">分项汇总一 (CNY)</span>
        <span style="color: #2F4891;font-size: 15px;">{{price}}</span>
      </div>
    </div>
    <!-- <van-action-sheet v-model="showCategory" title="请选择项目分项">
      <div style="height:500px;overflow-y:auto;padding:22px 20px;">
        <div
          v-for="(item, index) in parentCategoryList"
          :key="'parent_'+index"
          class="flow-category"
        >
          <div class="flow-category-img"></div>
          <div class="flow-category-body" v-if="item.hasChildrenCount == 0">
            <div v-if="index < (parentCategoryList.length - 1)" class="flow-category-line"></div>
            <span style="width:80%;margin-left:10px">{{item.name}}</span>
          </div>
          <div class="flow-category-body" v-else @click="getProductCategory(item)">
            <div v-if="index < (parentCategoryList.length - 1)" class="flow-category-line"></div>
            <span style="width:80%;margin-left:10px">{{item.name}}</span>
            <span style="float:right">
              <van-icon name="arrow" color="#2F4891"/>
            </span>
          </div>
        </div>
        <div style="border-top: 1px solid #E9E9E9;width:100%;height:1px;"></div>
        <div style="font-size: 16px;font-weight: 500;margin-left:8px;margin:15px 0;">请选择子分类</div>
        <van-radio-group v-model="checked" shape="square">
          <div
            v-for="(item, index) in childenCategoryList"
            :key="'child_'+index"
            class="flow-category-cell"
          >
            <span style="width:80%;">{{item.name}}</span>
            <span style="float:right">
              <van-radio :name="item.category_id"/>
            </span>
          </div>
        </van-radio-group>
        <van-tabbar>
          <van-tabbar-item>
            <div style="margin-bottom: 10px;">
              <van-button class="cancel" @click="onSearch('cancel')">重置</van-button>
              <van-button class="confirm" type="primary" @click="onSearch('confirm')">确认</van-button>
            </div>
          </van-tabbar-item>
        </van-tabbar>
      </div>
    </van-action-sheet> -->
  </div>
</template>

<script>
import Top from "../../../components/top";
import { productItemIndex, crmCategoryChilden, productCategoryIndex, getParentCategory } from "../../../api/user";
import { showTime } from "../../../utils/tool";
import {
  Toast,
  Button,
  Icon,
  Pagination,
  ActionSheet,
  Tabbar,
  TabbarItem,
  Cascader
} from "vant";
import Loading from "../../../components/loading";
import { mapState } from "vuex";
import { timeout } from 'q';

export default {
  name: "material",
  components: {
    Toast,
    Button,
    Icon,
    Pagination,
    ActionSheet,
    Loading,
    Tabbar,
    TabbarItem,
    Cascader
  },
  props: {
    id: [String, Number],
    price: [String, Number]
  },
  data() {
    return {
      showLoading: false,
      itemList: [],
      listCount: 0,
      pagesize: 10,
      page: 1, //页数
      itemCount: 0, //总数
      //分项选择器
      showCategory: false,
      parentCategoryList: [{ category_id: 0, name: "TOP" }],
      parentCategoryStr: 'TOP',
      childenCategoryList: [],
      checked: 0,
      //联动选择
      cascaderValue: 0,
      cascaderOptions: []
    };
  },
  created() {},
  computed: {},
  methods: {
    showTime,
    async gotoChange(current) {
      this.page = current;
      this.getProductItemMaterial();
    },
    async getProductItemMaterial() {
      this.showLoading = true;
      var params = {
        type: "save",
        page: this.page,
        limit: this.pagesize,
        product_id: this.id
      };
      if(this.cascaderValue){
        params.category_id = this.cascaderValue
      }
      let res = await productItemIndex(params);
      if (res.code == 200) {
        this.getProductCategory()
        this.itemList = res.data.list;
        this.itemCount = res.data.dataCount;
        this.showLoading = false;
      }
    },
    //选择子分项
    // async getProductCategory(category = {}) {
    //   this.showLoading = true;
    //   let category_id = category.category_id || 0;
    //   let res = await crmCategoryChilden({
    //     product_id: this.id,
    //     category_id: category_id
    //   });
    //   if (res.code == 200) {
    //     this.childenCategoryList = res.data;
    //     if (category_id == 0) {
    //       category = res.data[0];
    //       this.checked = category.category_id;
    //       this.parentCategoryList.push(category);
    //     }
    //     //处理的展示
    //     // const obj = this.parentCategoryList.filter((item, index) => {
    //     //   item.category_id == category.category_id;
    //     // });
    //     // console.log(this.parentCategoryList);
    //     // console.log(category);
    //     // console.log(obj);
    //     // if (obj.length == 0) {
          
    //     // }
    //     this.showLoading = false;
    //   }
    // },
    //
    // onSearch(type) {
    //   if (type == "confirm" && this.checked == 0) {
    //     Toast.fail("请选择子分类");
    //     return;
    //   } else if (type == "cancel") {

    //     this.checked = 0;
    //   }
    //   this.showCategory = false;
    //   this.getProductItemMaterial();
    // }
    //获得父级
    async getParentCategory() {
      let res = await getParentCategory({
        category_id: this.cascaderValue
      });
      if (res.code == 200) {
        this.parentCategoryStr = res.data.map((option) => option.name).join(' > ');
      }
    },
    //加在所有分项
    async getProductCategory() {
      let res = await productCategoryIndex({
        product_id: this.id,
        type: 'tree'
      });
      if (res.code == 200) {
        // this.cascaderValue = res.data[0].category_id
        this.cascaderOptions = res.data;
      }
    },
    async onFinish(e, node) {
      this.cascaderValue = node.data.category_id
      this.page = 1;
      this.showCategory = false;
      setTimeout(() => {
        this.getProductItemMaterial();
        this.getParentCategory();
      }, 500);
    },
  },
  watch: {
    id: {
      handler(val) {
        // console.log("项目id是   ", val);
        if (val) {
          this.getProductItemMaterial();
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="less">
/*用户信息*/
.list_body {
  .category-li {
    width: 100%;
    height: 33px;
    background: linear-gradient(90deg, #e9f4fe 0%, #fcf5ed 100%);
    border-radius: 3px;
    padding: 8px 11px;
    margin-bottom: 10px;
  }
  .article-li-header {
    width: 100%;
    height: 30px;
    background: #f0f2f4;
    display: flex;
  }
  .article-list {
    margin: 8px 8px 0 7px;
    height: 36px;
  }
  .li-value {
    display: block;
    font-size: 13px;
    height: 36px;
  }

  table {
    width: 100%;
    margin-top: 12px;
    color: #333;
    font-size: 14px;
    line-height: 1.5;
    border-collapse: collapse;
    table-layout: fixed;
  }

  table th {
    padding: 8px;
    text-align: left;
    background-color: #f0f2f4;
  }

  table th:first-child {
    padding-left: 0;
  }

  table th:last-child {
    padding-right: 0;
  }

  table td {
    word-wrap: break-word;
    padding: 8px 0;
    border-top: 1px solid #e9e9e9;
  }

  table td:last-child {
    padding-right: 0;
    color: #2f4891;
  }

  .footer-li {
    height: 40px;
    background: linear-gradient(180deg, #f7f7f7 0%, #fcfcfc 100%);
    padding: 11px 0;
    text-align: right;
    margin-top: 10px;
    margin-right: 7px;
  }
}
.flow-category {
  position: relative;
  padding-bottom: 15px;
  .flow-category-img {
    width: 7px;
    height: 7px;
    background: #4572c6;
    border-radius: 100%;
    float: left;
    position: absolute;
    z-index: 99;
  }
  .flow-category-body {
    font-size: 15px;
    font-weight: 400;
    color: #4572c6;
    padding-left: 15px;
    height: 36px;
  }
  .flow-category-line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0px;
    bottom: 0px;
    left: 3px;
    background-color: #4572c6;
  }
}
.flow-category-cell {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;
}
.cancel {
  width: 85px;
  height: 45px;
  border: 1px solid #666666 !important;
  border-radius: 5px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  border: none;
  margin-right: 5px;
}
.confirm {
  width: 233px;
  height: 45px;
  background: linear-gradient(105deg, #648ac2 0%, #46558c 100%);
  border: 1px solid #46558c !important;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  border: none;
}
</style>